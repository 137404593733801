<template>
    <div style="height: calc(100% - 50px); margin-bottom: 50px; overflow: auto;">
        <v-container>
            <v-data-iterator
                :items="selectedItems"
                no-data-text=""
                :no-results-text="`${$t('no_matching')} -  -`"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :search="table.searchText"
                :footer-props="{
                    itemsPerPageOptions: [25,50,100,-1],
                    itemsPerPageText: '',
                }"
                wrap>
                <template v-slot:item="props">

                    <div class="tile-container">

                        <v-skeleton-loader v-if="!loaded"
                            class="mx-auto"
                            width="210"
                            type="card"
                        ></v-skeleton-loader>

                        <ContactTile v-else
                            :id="props.item.id"
                            :enabled="props.item.enabled"
                            :name="props.item.name"
                            :surname="props.item.surname"
                            :role="props.item.role"
                            :gender="props.item.gender"
                            :company="props.item.company"
                            :phones="props.item.numbers ? props.item.numbers.split(',').map(number => ({ name: number.trim() })) : []"
                            :mails="props.item.mails ? props.item.mails.split(',').map(mail => ({ name: mail.trim() })) : []"
                            :projects="props.item.projects ? props.item.projects.map(project => ({ id: project.id, name: project.name })) : []"
                            :note="props.item.note"
                            :timezone="props.item.timezone"
                            :editable="true"
                            :languages="props.item.languages ? props.item.languages.split(',').map(language => language.trim()).join(', ') : ''"
                            :createdAt="props.item.createdAt"
                            :updatedAt="props.item.updatedAt"
                            @edit-item="editContact(props.item)" />
                    </div>

                </template>
            </v-data-iterator>
        </v-container>
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from '../_helpers'
import ContactTile from '@/components/ContactTile.vue'

export default {
    data() {
        return {
            items: [],
            loaded: false,
            page: 1,
            itemsPerPage: 50,
            selectedItem: null,
        }
    },

    components: {
        ContactTile,
    },

    computed: {
        ...mapState({
            table: 'table',
        }),

        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },

        selectedItems() {
            if (this.selectedItem) {
                return this.items.filter(item => {
                    if (this.selectedItem.id) {
                        return item.id === this.selectedItem.id
                    }
                    if (this.selectedItem.projectId) {
                        return item.projects.filter(project => project.id === this.selectedItem.projectId).length
                    }
                    return true
                })
            }
            return this.items
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setInputDialog: 'input/setInputDialog',
            showInputDialog: 'input/showInputDialog',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        getRows() {
            const payload = {
                includes: [{
                    type: 'project',
                    attributes: ['id', 'name'],
                }],
            }

            rest.getRows('contact', payload)
                .then(items => {
                    if (this.$route.name === 'contactId' && this.$route.params.id) {
                        // const idPos = items.map(x => x.id).indexOf(parseInt(this.$route.params.id, 10))
                        this.selectedItem = { id: Number.parseInt(this.$route.params.id, 10) }
                    } else if (this.$route.name === 'contactProjectId' && this.$route.params.id) {
                        // const idPos = items.map(x => x.id).indexOf(parseInt(this.$route.params.id, 10))
                        this.selectedItem = { projectId: Number.parseInt(this.$route.params.id, 10) }
                    }

                    items.forEach(item => {
                        if (item.projects) {
                            item.projectName = item.projects.map(project => project.name).join(', ')
                        }
                    })
                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                    this.loaded = true
                })
        },

        editContact(item) {
            const input = {
                id: this.items.indexOf(item),
                visible: true,
                type: 'contact',
                title: this.$t('Contact'),
                data: ({ ...item }),
                modified: (rowId, rowData) => {
                    if (rowId > -1) {
                        this.$set(this.items, rowId, rowData)
                    }

                    this.showInputDialog(false)
                },
                preValidate: this.preValidate,
            }

            this.setInputDialog(input)
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.setNew({
            type: 'contact',
            title: 'Contact',
            modified: (idx, item) => {
                this.items.push(item)

                this.resetNew()
            },
        })

        this.getRows()
    },
}
</script>

<style lang="stylus" scoped>
    .tile-container
        margin 2em
        float left

    #tiles-container
        display inline-flex
        width 100%
</style>

<style lang="stylus">
    .v-data-footer
        width 100%
        position fixed
        bottom 0
        left 0
        clear both
        background-color white
</style>
