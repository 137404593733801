<template>
    <DataTable :headers="headers"
        :data="items"
        :selectedItem="selectedItem"
        :expandable="expandable"
        :editable="editable"
        :title="$t('computer.name')"
        :pagination="{sortBy: ['name'], itemsPerPage: -1}"
        type="computer" />
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    data() {
        return {
            headers: [
                {
                    text: this.$t('name'),
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('check_date'),
                    align: 'left',
                    sortable: true,
                    value: 'checkDate',
                    type: 'date',
                },
                {
                    text: this.$t('last_maintain'),
                    align: 'left',
                    sortable: true,
                    value: 'lastMaintain',
                    type: 'date',
                },
                {
                    text: this.$t('internal_free'),
                    align: 'left',
                    sortable: true,
                    value: 'intHddFree',
                    type: 'int',
                    repr: (row, value) => (value ? `${value.toFixed(2)} GB` : ''),
                    level: item => {
                        let level = 0
                        if (item.intHddTotal > 0) {
                            const result = (item.intHddFree / item.intHddTotal) * 100
                            if (result <= 10) {
                                level = 2
                            } else if (result <= 20) {
                                level = 1
                            }
                        }
                        return level
                    },
                },
                {
                    text: this.$t('internal_total'),
                    align: 'left',
                    sortable: true,
                    value: 'intHddTotal',
                    type: 'int',
                    repr: (row, value) => (value ? `${value.toFixed(2)} GB` : ''),
                },
                {
                    text: this.$t('external_free'),
                    align: 'left',
                    sortable: true,
                    value: 'extHddFree',
                    type: 'int',
                    repr: (row, value) => (value ? `${value.toFixed(2)} GB` : ''),
                    level: item => {
                        let level = 0
                        if (item.intHddTotal > 0) {
                            const result = (item.extHddFree / item.extHddTotal) * 100
                            if (result <= 10) {
                                level = 2
                            } else if (result <= 20) {
                                level = 1
                            }
                        }
                        return level
                    },
                },
                {
                    text: this.$t('external_total'),
                    align: 'left',
                    sortable: true,
                    value: 'extHddTotal',
                    type: 'int',
                    repr: (row, value) => (value ? `${value.toFixed(2)} GB` : ''),
                },
                {
                    text: this.$t('hotspot'),
                    align: 'center',
                    sortable: false,
                    value: 'hotspot',
                    type: 'bool',
                },
                {
                    text: this.$t('software'),
                    align: 'center',
                    sortable: true,
                    value: 'MKLapseVer',
                },
                {
                    text: 'Teamviewer',
                    align: 'center',
                    sortable: false,
                    value: 'Teamviewer',
                },
                {
                    text: this.$t('inv'),
                    align: 'center',
                    sortable: false,
                    value: 'invId',
                    type: 'link',
                    href: item => `/inventory/${item.inventory.id}`,
                    linkIf: item => item.inventory,
                    linkIcon: 'mdi-link',
                },
                {
                    text: this.$t('system'),
                    align: 'center',
                    sortable: true,
                    width: '6em',
                    value: 'systemName',
                    type: 'link',
                    href: item => `/system/${item.inventory.system.id}`,
                    linkIf: item => item.inventory && item.inventory.system && item.inventory.system.id && item.inventory.system.enabled,
                    linkName: item => `${item.inventory.system.project.name} ${item.inventory.system.camNo}`,
                },
                {
                    text: this.$t('prev'),
                    align: 'left',
                    sortable: false,
                    value: 'plugs',
                    width: '3em',
                    type: 'menu',
                    menuItems: item => {
                        const items = []
                        if (item.inventory) {
                            let lastSystemId = null
                            if (item.inventory.plugs) {
                                item.inventory.plugs.forEach(plug => {
                                    if (plug.system) {
                                        if (plug.systemId !== lastSystemId) {
                                            const prevItem = {
                                                title: `${plug.system.project.name} ${plug.system.camNo}`,
                                                href: () => `/system/${plug.systemId}`,
                                                from: plug.datePlugged,
                                                to: plug.dateUnplugged,
                                            }
                                            items.push(prevItem)

                                            lastSystemId = plug.systemId
                                        } else {
                                            items[items.length - 1].to = plug.dateUnplugged
                                        }
                                    }
                                })
                            }
                        }

                        return items.sort((a, b) => a.from > b.from)
                    },
                    linkIcon: 'mdi-format-list-bulleted',
                },
                {
                    text: this.$t('project.name'),
                    align: ' d-none',
                    hidden: true,
                    value: 'projectName',
                    // reset: item => { item.projectName = item.inventory.system.project.name },
                },
            ],
            items: [],
            expandable: true,
            editable: true,
            selectedItem: null,
        }
    },

    components: {
        DataTable,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
        }),

        getRows() {
            const payload = {
                attributes: {
                    include: [['inventory.system.project.name', 'projectName']],
                },
                includes: [{
                    type: 'note',
                    attributes: ['id'],
                },
                {
                    type: 'inventory',
                    attributes: ['id'],
                }],
            }

            rest.getRows('computer', payload)
                .then(items => {
                    if (this.$route.params.id) {
                        const idPos = items.map(x => x.id).indexOf(parseInt(this.$route.params.id, 10))
                        this.selectedItem = items[idPos]
                    }

                    items.forEach(item => {
                        if (item.inventory && item.inventory.system && item.inventory.system.project) {
                            item.systemName = `${item.inventory.system.project.name} ${item.inventory.system.camNo}`
                        }
                    })

                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.getRows()
    },
}
</script>
