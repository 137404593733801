import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"calc(100% - 50px)","margin-bottom":"50px","overflow":"auto"}},[_c(VContainer,[_c(VDataIterator,{attrs:{"items":_vm.selectedItems,"no-data-text":"","no-results-text":((_vm.$t('no_matching')) + " -  -"),"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.table.searchText,"footer-props":{
                    itemsPerPageOptions: [25,50,100,-1],
                    itemsPerPageText: '',
                },"wrap":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('div',{staticClass:"tile-container"},[(!_vm.loaded)?_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"width":"210","type":"card"}}):_c('ContactTile',{attrs:{"id":props.item.id,"enabled":props.item.enabled,"name":props.item.name,"surname":props.item.surname,"role":props.item.role,"gender":props.item.gender,"company":props.item.company,"phones":props.item.numbers ? props.item.numbers.split(',').map(function (number) { return ({ name: number.trim() }); }) : [],"mails":props.item.mails ? props.item.mails.split(',').map(function (mail) { return ({ name: mail.trim() }); }) : [],"projects":props.item.projects ? props.item.projects.map(function (project) { return ({ id: project.id, name: project.name }); }) : [],"note":props.item.note,"timezone":props.item.timezone,"editable":true,"languages":props.item.languages ? props.item.languages.split(',').map(function (language) { return language.trim(); }).join(', ') : '',"createdAt":props.item.createdAt,"updatedAt":props.item.updatedAt},on:{"edit-item":function($event){return _vm.editContact(props.item)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }